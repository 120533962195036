import { env } from "./env.config";

const appConfig = {
  apiHYS: env.API_ENDPOINT_URL + "/v1",
  apiPrefix: env.API_ENDPOINT_URL + "/v1",
  // apiPrefix: '/api',
  authenticatedEntryPath: "/app/admin",
  frontendPath: "/frontend",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/app/account/kyc-form",
  locale: "en",
  enableMock: false,
};

export default appConfig;
