import React from "react";
import { AdaptableCard, RichTextEditor } from "components/shared";
import { Input, FormItem, Avatar, Badge } from "components/ui";
import { Field } from "formik";
import { HiOutlinePaperClip } from "react-icons/hi";
import CloseButton from "components/ui/CloseButton";
import isEmpty from "lodash/isEmpty";

const MessageFields = (props) => {
  const { touched, errors, mresponse, state, setState } = props;

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const removeFile = (fileIndex) => {
    console.log(fileIndex);
  };

  return (
    <AdaptableCard className="mb-4">
      <h1>Message Manager</h1>
      <p>
        {capitalizeFirstLetter(mresponse?.from_title)} Send | Send Message from{" "}
        {mresponse?.from_name}
      </p>
      {mresponse?.file !== null && (
        <Badge className="mr-4">
          <Avatar icon={<HiOutlinePaperClip />} />
        </Badge>
      )}

      {!isEmpty(mresponse?.thumbnail) && (
        <Avatar className="mr-4" src={mresponse?.profileThumb} />
      )}

      <h3>{mresponse?.from_name}</h3>

      <p>
        From {capitalizeFirstLetter(mresponse?.from_title)} to{" "}
        {mresponse?.recipients.length} {mresponse?.to}
      </p>

      <div>
        <p>
          To {capitalizeFirstLetter(mresponse?.to)} :
          {!isEmpty(mresponse?.recipients) &&
            mresponse?.recipients.map((item) => (
              <>
                {mresponse?.from === "donor" ? (
                  <>
                    <span className="text-blue-500">{item?.name}</span>
                  </>
                ) : (
                  <>
                    <span className="text-blue-600">{item?.name}</span>
                  </>
                )}
              </>
            ))}
        </p>
      </div>

      <FormItem
        label="Subject"
        invalid={errors.subject && touched.subject}
        errorMessage={errors.subject}
      >
        <Field
          type="text"
          autoComplete="off"
          name="subject"
          component={Input}
        />
      </FormItem>

      <FormItem
        label="Message Body"
        labelClass="!justify-start"
        invalid={errors.message && touched.message}
        errorMessage={errors.message}
      >
        <Field name="message">
          {({ field, form }) => (
            <RichTextEditor
              value={field.value}
              onChange={(val) => form.setFieldValue(field.name, val)}
              state={state}
              setState={setState}
            />
          )}
        </Field>
      </FormItem>

      {mresponse?.file !== null && (
        <div className="flex items-center">
          <span className="text-gray-500">Attachment:</span>
          {mresponse?.file?.type === "image" ? (
            <>
              <div className="min-w-full md:min-w-[200px] flex items-center md:mb-0 mb-2 ltr:md:mr-3 rtl:md:ml-3 cursor-pointer select-none border px-4 py-2 border-gray-200 dark:border-gray-600 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-600">
                <div className="text-3xl">
                  {" "}
                  <Avatar className="mr-4" src={mresponse?.thumbnail} />
                </div>
                <div className="ltr:ml-2 rtl:mr-2">
                  <div className="font-semibold text-gray-900 dark:text-gray-100">
                    {mresponse?.file?.file_name}
                    <CloseButton
                      onClick={() => removeFile(mresponse?.file?.id)}
                      className="upload-file-remove"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <p>{mresponse?.file?.file_name}</p>
            </>
          )}
        </div>
      )}
    </AdaptableCard>
  );
};

export default MessageFields;
