import React from "react";
import classNames from "classnames";
import GridItem from "./GridItem";
import { Spinner } from "components/ui";
import PropTypes from 'prop-types';

const ProductView = ({ row, files, fetchData, setIsOpen, setSelectedFile, onReload, isSmallImage}) => {
    return (
      <>
          <GridItem 
            key={row.id} 
            data={row} 
            files={files}
            fetchData={fetchData}
            setIsOpen={setIsOpen}
            setSelectedFile={setSelectedFile}
            onReload={onReload}
            isSmallImage={isSmallImage}
        />
      </>
    );
  };

  ProductView.propTypes = {
    row: PropTypes.object.isRequired,
    files: PropTypes.any,
    fetchData: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    setSelectedFile: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
    isSmallImage: PropTypes.bool,
  };

const CardView = (props) => {
  const { 
    data, 
    files, 
    loading, 
    fetchData, 
    setIsOpen, 
    setSelectedFile,
    onReload,
    isDualColumn,
    isSmallImage
} = props;
    const gridColumnClass = isDualColumn ? "xl:grid-cols-2" : "xl:grid-cols-1";

  return (
    <div
      className={classNames(
        "mt-3 h-full flex flex-row",
        loading && "justify-center"
      )}
    >
      {loading && (
        <div className="flex justify-center">
          <Spinner size={40} />
        </div>
      )}
      {data?.length > 0 && !loading && (
        <div className={`grid md:grid-cols-1 lg:grid-cols-1 ${gridColumnClass} gap-4`}>
          {data?.map((project) => (
            <React.Fragment key={project.id}>
              <ProductView 
                row={project} 
                files={files} 
                fetchData={fetchData} 
                setIsOpen={setIsOpen} 
                setSelectedFile={setSelectedFile}
                onReload={onReload}
                isSmallImage={isSmallImage}
                />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

CardView.propTypes = {
  data: PropTypes.array,
  files: PropTypes.any,
  loading: PropTypes.bool,
  fetchData: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  isDualColumn: PropTypes.bool,
  isSmallImage: PropTypes.bool, 
};

export default CardView;
