import ApiService from "./ApiService";

export async function apiGetDonationsList(data) {
  return ApiService.fetchData({
    url: "/admin/donations/options",
    method: "get",
  });
}

export async function apiGetFormFields(data) {
  return ApiService.fetchData({
    url: `/admin/form/${data.Donor_Group}/fields`,
    method: "get",
  });
}

export async function apiGetAllDonations(params) {
  return ApiService.fetchData({
    url: "/admin/donations/1",
    method: "get",
    params
  });
}

export async function apiGetDonation(data) {
  return ApiService.fetchData({
    url: "/admin/donations",
    method: "post",
    data,
  });
}

export async function apiAllDonationsByDonors(data) {
  return ApiService.fetchData({
    url: `/admin/donors/donations`,
    method: "get",
    data,
  });
}

export async function apiDonationsByDonor(data) {
  return ApiService.fetchData({
    url: `/admin/donors/${data.donor_id}/donations`,
    method: "get",
    data,
  });
}

export async function apiAddDonation(data) {
  return ApiService.fetchData({
    url: "/admin/donation",
    method: "post",
    data,
  });
}

export async function apiGetEditDonation(data) {
  return ApiService.fetchData({
    url: `/admin/donation/${data.donation_id}`,
    method: "get",
  });
}

export async function apiUpdateDonation(data) {
  return ApiService.fetchData({
    url: `/admin/donation/${data.donation_id}`,
    method: "put",
    data,
  });
}

export async function apiDeleteDonation(data) {
  return ApiService.fetchData({
    url: `/admin/donation/${data.donation_id}`,
    method: "delete",
    data,
  });
}

//export the donations
export async function apiGetExportDonations(){
  return ApiService.fetchData({
    url: `/admin/export_donations`,
    method: "get",
  });
}
