import React, { useState } from "react";
import { AdaptableCard, RichTextEditor } from "components/shared";
import { FormItem, Select, Pagination } from "components/ui";
import { Field } from "formik";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiPencil } from "react-icons/hi";
import { getNotes, getNote } from '../../donors/DonorDetail/store/dataSlice'
import { openEditNote } from "../../donors/DonorDetail/store/stateSlice";
import CreatableSelect from "react-select/creatable";
import reducer from '../../programs/RecipientNotesEdit/store'
import { injectReducer } from 'store/index'
import { getNote as getEntityNotes } from '../../programs/RecipientNotesEdit/store/dataSlice'
import moment from 'moment';

injectReducer('noteEdit', reducer)

const NotesFields = (props) => {
  const [ page, setPage ] = useState(1)
  const [ itemsPerPage ] = useState(3)

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const { touched, errors, values } = props;
  const location = useLocation();
  const path = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  const programId = useSelector((state) => state?.getDonorDetails?.data?.donorData?.data?.hysform?.type)

  const progId = programId !== undefined ? programId : path 
  const donor = location.state?.donor;
 
  const dispatch = useDispatch();

  const { donor_id } = useParams();

  const categories = useSelector(
    (state) => state.getDonorDetails?.data?.notesList.categories
  );

  const categoriesEntities = useSelector(
    (state) => state.entitiesList?.data?.notesList.categories
  );

  const catCheck = categories !== undefined ? categories : categoriesEntities

  const recipientName = useSelector(
    (state) => state.getDonorDetails?.data?.notesList?.name
  );

  const notes = useSelector(
    (state) => state.getDonorDetails?.data?.notesList.notes
  );

  const notesEntity = useSelector(
    (state) => state.entitiesList?.data?.notesList.notes
  );

  const notesCheck = notes !== undefined ? notes : notesEntity

  const { client_id } = useSelector((state) => state.auth.user);

  const entityId = useSelector(
    (state) => state.getDonorDetails?.state?.entityState
  );


  const categoryArray = [{
    label: "ALL",
    value: "ALL"
  }];

  catCheck?.forEach(function (category, i) {
    categoryArray.push({
      label: category.category,
      value: category.category,
      id: category.id,
    });
  });

  let merged = [];

  for (let i = 0; i < notesCheck?.length; i++) {
    merged.push({
      ...catCheck?.find((itmInner) => itmInner.id === notesCheck[i]?.category_id),
      ...notesCheck[i],
    });
  }

  const currentItems = merged.slice(indexOfFirstItem, indexOfLastItem);

  const selectCat = (selected) => {
    if (selected.value === "ALL") {
      onSelectAllCategories();
    } else {
      onSelectCategory(selected);
    }
  }

  const onSelectCategory = (selected) => {
    setPage(1)
    if (donor) {
      dispatch(
        getNotes({
          id: donor_id,
          program_id: "donor",
          type: "donor",
          cat: selected.id,
          client_id,
        })
      );
    } else {
      setPage(1)
      dispatch(
        getNotes({
          id: entityId,
          program_id: progId,
          type: "entity",
          cat: selected.id,
          client_id,
        })
      );
    }
  };
  const onSelectAllCategories = () => {
    if (donor) {
      dispatch(
        getNotes({
          id: donor_id,
          program_id: donor,
          type: "donor",
          cat: "",
          client_id,
        })
      );
    } else {
      dispatch(
        getNotes({
          id: entityId,
          program_id: progId,
          type: "entity",
          cat: "",
          client_id,
        })
      );
    }
  };

  const onNavigate = (id) => {
    if (donor && donor !== undefined) {
      dispatch(openEditNote());
      dispatch(getNote({
        note_id: id,
        client_id,
        program_id: progId,
      }))
    } else {
      dispatch(openEditNote());
      dispatch(getEntityNotes({
        note_id: id,
        client_id,
        program_id: progId,
      }))
    }
      
  };

  const onPaginationChange = (page) => {
    setPage(page)
  }

  return (
    <>
      {recipientName && <h5 className="mb-5">{recipientName}'s Notes</h5>}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      <div className="lg:col-span-1">
          <p className="mb-3">VIEW BY CATEGORY</p>
          <Select
            placeholder="ALL"
            options={categoryArray}
            size="sm"
            className="max-w-md md:w-52 md:mb-0 mb-4 mr-2"
            onChange={selectCat}
          />
          <div className="mt-5">
          <h5>Notes</h5>
          <div className="mt-3">
            <>
              {currentItems && currentItems.length !== 0
                ? currentItems.map((item) => (
                    <div
                      key={item.id}
                      className="mb-5"
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() => onNavigate(item.id)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <>
                          <p>{item.note}</p>
                        </>
                        <>
                          <HiPencil className="text-emerald-500 text-xl" />
                        </>
                      </div>
                      <div>
                        <p>Created: {moment.utc(item.created_at).format('MM/DD/YYYY')}</p>
                      </div>
                      <div>
                        <p>Category: {item.category}</p>
                      </div>
                    </div>
                  ))
                : null}
            </>
            <Pagination
              pageSize={3}
              onChange={onPaginationChange}
              total={merged.length}
              currentPage={page}
            />
          </div>
        </div>
        </div>
        
        <div className="lg:col-span-1">
          <AdaptableCard className="mb-4">
            <FormItem
              labelClass="!justify-start"
              invalid={errors.note && touched.note}
              errorMessage={errors.note}
            >
              <Field name="note">
                {({ field, form }) => (
                  <RichTextEditor
                    value={field.value}
                    onChange={(val) => form.setFieldValue(field.name, val)}
                  />
                )}
              </Field>
            </FormItem>
            <FormItem
              label="Category"
              invalid={errors.categories && touched.categories}
              errorMessage={errors.categories}
            >
              <Field name="categories">
                {({ field, form }) => (
                  <Select
                    componentAs={CreatableSelect}
                    isMulti
                    field={field}
                    form={form}
                    options={categoryArray}
                    value={values.categories}
                    onChange={(option) =>
                      form.setFieldValue(field.name, option)
                    }
                  />
                )}
              </Field>
            </FormItem>
          </AdaptableCard>
        </div>
      </div>
    </>
  );
};

export default NotesFields;
