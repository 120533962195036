import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiGetEntitiesList,
  apiDeleteProgramsSettings,
  apiGetFieldOptionsList,
  apiGetRecipientFields,
  apiDeleteReport,
  apiArchiveProgram,
  apiGetMoveEntityFields,
  apiGetDefaultFieldOptionsList,
  apiArchiveEntity,
  apiRestoreEntity,
  apiRestoreEntities,
  apiDeleteEntity,
  apiGetNotes,
  apiGetReports,
  apiGetSavedReport,
  apiSendEmail,
  apiDeleteEntities,
  apiRedirectToFrontendRandomEntity,
  apiGetProgramStatistics,
  apiUpdateEntities,
} from "services/ProgramsService";

export const getEntities = createAsyncThunk(
  "entities/getEntities",
  async (params) => {
    const response = await apiGetEntitiesList(params);
    return response.data;
  }
);

export const getFieldOptions = createAsyncThunk(
  "fieldOptionList/getFieldOptions",
  async (data) => {
    const response = await apiGetFieldOptionsList(data);
    return response.data;
  }
);

export const getDefaultFieldOptions = createAsyncThunk(
  "fieldDefaultOptionList/getDefaultFieldOptions",
  async (data) => {
    const response = await apiGetDefaultFieldOptionsList(data);
    return response.data;
  }
);

export const getRecipientFields = createAsyncThunk(
  "recipientFields/getRecipientFields",
  async (data) => {
    const response = await apiGetRecipientFields(data);
    return response.data;
  }
);

export const getMoveEntity = createAsyncThunk(
  "entitiesList/getMoveEntityFields",
  async (data) => {
    const response = await apiGetMoveEntityFields(data);
    return response.data;
  }
);

export const getProgramStatistics = createAsyncThunk(
  "entitiesList/getProgramStatistics",
  async (params) => {
    const response = await apiGetProgramStatistics(params);
    return response.data;
  }
);

export const deletePrograms = async (data) => {
  const response = await apiDeleteProgramsSettings(data);
  return response.data;
};

export const deleteReport = async (data) => {
  const response = await apiDeleteReport(data);
  return response.data;
};

export const getSavedReportColumn = async (data) => {
  const response = await apiGetSavedReport(data);
  return response.data;
};

export const updateEntities = async (data) => {
  const response = await apiUpdateEntities(data)
  return response.data
};

export const archivePrograms = async (data) => {
  const response = await apiArchiveProgram(data);
  return response.data;
};

export const archiveEntity = createAsyncThunk(
  "entitiesList/archiveEntity",
  async (data) => {
    const response = await apiArchiveEntity(data);
    return response.data;
  }
);

export const restoreEntity = createAsyncThunk(
  "entitiesList/restoreEntity",
  async (data) => {
    const response = await apiRestoreEntity(data);
    return response.data;
  }
);

export const restoreEntities = async (data) => {
  const response = await apiRestoreEntities(data);
  return response.data;
};

export const deleteEntity = createAsyncThunk(
  "entitiesList/deleteEntity",
  async (data) => {
    const response = await apiDeleteEntity(data);
    return response.data;
  }
);

export const deleteEntities = async (data) => {
  const response = await apiDeleteEntities(data);
  return response.data;
};

export const getNotes = createAsyncThunk(
  "entitiesList/getNotes",
  async (data) => {
    const response = await apiGetNotes(data);
    return response.data;
  }
);

export const getReports = createAsyncThunk(
  "entitiesList/getReports",
  async (data) => {
    const response = await apiGetReports(data);
    return response.data;
  }
);

export const sendEmail = async (data) => {
  const response = await apiSendEmail(data);
  return response.data;
};

export const redirectToFrontendRandomEntity = async (data) => {
  const response = await apiRedirectToFrontendRandomEntity(data);
  return response.data;
};

export const initialTableData = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sortOrder: "",
  type:"",
  filter:"",
  sortKey: "",
  keysArray: [],
  equal_to: ""
};

export const initialFilterData = {
  status: "select",
};

const dataSlice = createSlice({
  name: "entitiesList/data",
  initialState: {
    loading: true,
    statisticsLoading: false,
    fieldsLoading: false,
    recipientsLoading: false,
    defaultFieldsLoading: false,
    moveEntityDataLoading: false,
    notesLoading: false,
    entitiesList: [],
    programStatistics: [],
    moveEntitiesList: [],
    fieldsList: [],
    recipientsList: [],
    defaultFieldsList: [],
    dataLength: [],
    notesList: [],
    tableData: initialTableData,
    filterData: initialFilterData,
    archivedState: null,
    initialDataLength: 0,
  },
  reducers: {
    updateEntitiesList: (state, action) => {
      state.entitiesList = action.payload;
    },

    updateDefaultFieldsList: (state, action) => {
      state.defaultFieldsList = action.payload;
    },
    updateRecipientsList: (state, action) => {
      state.recipientsList = action.payload;
    },
    updateMoveEntitiesList: (state, action) => {
      state.moveEntitiesList = action.payload;
    },
    updateNotesList: (state, action) => {
      state.notesList = action.payload;
    },
    setTableData: (state, action) => {
      state.tableData = { ...state.tableData, ...action.payload };
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    setDataLength: (state, action) => {
      state.initialDataLength = action.payload;
    },
  },
  extraReducers: {
    [getEntities.fulfilled]: (state, action) => {
      state.archivedState = action.payload.filter;
      state.entitiesList = action.payload.data;
      state.dataLength = action.payload.length;
      state.tableData.total = action.payload.length;
      state.loading = false;
    },
    [getFieldOptions.fulfilled]: (state, action) => {
      state.fieldsList = action.payload.data;
      state.fieldsLoading = false;
    },
    [getRecipientFields.fulfilled]: (state, action) => {
      state.recipientsList = action.payload;
      state.tableData.total = action.payload.total;
      state.recipientsLoading = false;
    },
    [getMoveEntity.fulfilled]: (state, action) => {
      state.moveEntitiesList = action.payload;
      state.tableData.total = action.payload.total;
      state.moveEntityDataLoading = false;
    },

    [getDefaultFieldOptions.fulfilled]: (state, action) => {
      state.defaultFieldsList = action.payload;
      state.defaultFieldsLoading = false;
    },

    [getNotes.fulfilled]: (state, action) => {
      state.notesList = action.payload;
      state.tableData.total = action.payload.total;
      state.notesLoading = false;
    },

    [getProgramStatistics.fulfilled]: (state, action) => {
      state.programStatistics = action.payload;
      state.statisticsLoading = false;
    },

    [getProgramStatistics.pending]: (state) => {
      state.statisticsLoading = true;
    },

    [getMoveEntity.pending]: (state) => {
      state.moveEntityDataLoading = true;
    },

    [getDefaultFieldOptions.pending]: (state) => {
      state.defaultFieldsLoading = true;
    },

    [getRecipientFields.pending]: (state) => {
      state.recipientsLoading = true;
    },
    [getFieldOptions.pending]: (state) => {
      state.fieldsLoading = true;
    },
    [getEntities.pending]: (state) => {
      state.loading = true;
    },
    [getNotes.pending]: (state) => {
      state.notesLoading = true;
    },
  },
});
export const {
  updateEntitiesList,
  setTableData,
  setFilterData,
  updateRecipientsList,
  updateMoveEntitiesList,
  updateDefaultFieldsList,
  updateNotesList,
  setDataLength
} = dataSlice.actions;
export default dataSlice.reducer;
