import React, { useState } from "react";
import { Card, Dropdown, Notification, toast, Tag, Dialog } from "components/ui";
import EllipsisButton from "components/shared/EllipsisButton";
import { apiMakeProfile, apiGetDeleteFile, apiGetRotateImage } from "services/ProgramsService";
import { useNavigate } from "react-router-dom";
import { HiEye, HiDownload } from 'react-icons/hi'
import { BsFileEarmarkPdf, BsFileEarmarkExcel, BsFileEarmarkWord } from "react-icons/bs";
import PropTypes from 'prop-types';

const GridItem = ({ data, files, fetchData, onReload, setIsOpen, setSelectedFile, isSmallImage }) => {
  const navigate = useNavigate();
  const {
    file_name,
    id,
    link,
    profile,
    thumb_link,
    type,
    permissions,
    created_at,
    updated_at
  } = data;

  const [ selectedImg, setSelectedImg ] = useState({})
  const [ selectedFile, setSelectedFileName ] = useState({})
  const [ viewOpen, setViewOpen ] = useState(false)

  const onViewOpen = (thumb_link, file_name) => {
    setSelectedImg(thumb_link)
    setSelectedFileName(file_name)
    setViewOpen(true)
  }

  const onDialogClose = () => {
    setViewOpen(false)
    setTimeout(() => {
        setSelectedImg({})
        setSelectedFileName({})
    }, 300)
  }

  const dropdownItems = [
    { key: "delete", name: "Delete", id: id },
    { key: "edit", name: "Edit", id: id }
  ];

  const addDropdownItem = (key, name, id) => {
    dropdownItems.unshift({ key, name, id });
  };

  const popNotification = (keyword) => {
    toast.push(
      <Notification title={`Successful`} type="success" duration={2500}>
        {keyword.data.message}
      </Notification>,
      {
        placement: "top-center",
      }
    );
    // Fresh the page
    fetchData();
  };

  const fileExtension = file_name.split('.').pop();

  if (type === "entity" && files?.n > 0) {
    const donorName = `(${files?.n}) Donor${files?.s}`;
    addDropdownItem("donor", files?.n === 1 ? "Send to Donor" : `Send to ${donorName}`, id);
  }

  if (files?.type === "donor" && files?.n > 0) {
    const entityName = `(${files?.n}) Recipient${files?.s}`;
    addDropdownItem("entity", files?.n === 1 ? "Send to Recipient" : `Send to ${entityName}`, id);
  }

  if (type === "Image") {
    addDropdownItem("rotate", "Rotate", id);
  }

  if (type === "Image") {
    const profileName = profile === 1 ? "Unset profile" : "Make profile";
    addDropdownItem("profile", profileName, id);
  }

  const handleMakeProfile = async (id) => {
    const success = await apiMakeProfile({ id });
    if (success) {
      popNotification(success);
    }
  };

  const handleDeleteFile = async (id) => {
    const success = await apiGetDeleteFile({ id });
    if (success) {
      popNotification(success);
    }
  };

  const handleRotateImage = async (id) => {
    const success = await apiGetRotateImage({ id });
    if (success) {
      popNotification(success);
    }
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    return formattedDate;
  }

  const onDropdownItemClick = async (item) => {
    switch (item.key) {
      case "profile":
        await handleMakeProfile(item.id);
        onReload();
        break;
      case "delete":
        await handleDeleteFile(item.id);
        break;
      case "rotate":
        await handleRotateImage(id);
        break;
      case "edit":
        setIsOpen(true)
        setSelectedFile(data);
        break;
      case "entity":
        const entityRoute = `/app/entites/send-message/${files.id}`;
        const entityState = {
          file_id: id,
          entity_id: files.n === 1 ? files.id : "all",
          from: "donor",
          donor_id: files.n === 1 && files?.sponsors && files?.sponsors?.length > 0 ? files?.sponsors?.[0]?.id : files?.id
        };
        navigate(entityRoute, { state: entityState });
        break;
      case "donor":
        const donorRoute = `/app/entites/send-message/${files.id}`;
        const donorState = {
          file_id: id,
          donor_id: files.n === 1 ? files.sponsors[0].id : "all",
          from: "entity",
          entity_id: files.id,
        };
        navigate(donorRoute, { state: donorState });
        break;
      default:
        break;
    }
  };

  const getIconByFileExtension = (fileExtension) => {
    const iconMappings = {
      pdf: <BsFileEarmarkPdf className="text-red-500" size={34}/>,
      xlsx: <BsFileEarmarkExcel className="text-green-500" size={34} />,
      xls: <BsFileEarmarkExcel className="text-green-500" size={34} />,
      docx: <BsFileEarmarkWord className="text-blue-500" size={34} />,
      doc: <BsFileEarmarkWord className="text-blue-500" size={34} />,
    };
  
    return iconMappings[fileExtension] || null;
  };  

  const imageHeightClass = isSmallImage ? 'h-[90px]' : 'h-[250px]';
  // 

  return (
    <>
        <div className="mt-3 group relative justify-between p-2">
                <div bodyClass="h-full" className="max-w-xs">
                    <Card
                        className="hover:shadow-lg transition duration-150 ease-in-out dark:border  dark:border-solid"
                        header={
                            type === "Image" ? 
                            <>
                            <div className={`rounded-tl-lg rounded-tr-lg overflow-hidden ${imageHeightClass}`}>
                                <img className="rounded w-full object-cover" src={thumb_link} alt={file_name} />
                            </div>
                            <div className={`absolute inset-2 bg-gray-900/[.7] group-hover:flex hidden text-xl items-center justify-center ${imageHeightClass} rounded`}>
                                <span onClick={() => onViewOpen(thumb_link, file_name)} className="text-gray-100 hover:text-gray-300 cursor-pointer p-1">
                                    <HiEye />
                                </span>
                                <span className="text-gray-100 hover:text-gray-300 cursor-pointer p-1">
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <HiDownload />
                                    </a>
                                </span>
                            </div> 
                            </>
                            : <div className="flex justify-center mt-4">
                                <a href={link}>
                                  {getIconByFileExtension(fileExtension)}
                                </a>
                            </div> 
                        }
                        headerClass="p-0"
                        footerBorder={false}
                        headerBorder={false}
                    >
                        <div className="flex justify-between items-center">
                        <h4 className="font-bold my-3" style={{maxWidth: '80%', wordWrap: 'break-word' }}>{file_name}</h4>
                            <span className="text-emerald-600 font-semibold">
                                <Dropdown placement="bottom-end" renderTitle={<EllipsisButton />}>
                                    {dropdownItems.map((item) => (
                                        <Dropdown.Item
                                            eventKey={item.key}
                                            key={item.key}
                                            onSelect={() => onDropdownItemClick(item)}
                                        >
                                            {item.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown>
                            </span>
                        </div>
                        <ul>
                            {
                              permissions === "Everyone" && (
                                <li>Permissions: {
                                    <Tag className="text-red-600 bg-red-100 dark:text-red-100 dark:bg-red-500/20 border-0">
                                        {permissions}
                                    </Tag>
                                    }</li>
                              )
                            }
                            {
                              permissions === "Admins Only" && (
                                <li>Permissions: {
                                    <Tag className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 border-0">
                                        {permissions}
                                    </Tag>
                                    }</li>
                              )
                            }
                            {
                              permissions === "Donor and Admins" && (
                                <li>Permissions: {
                                    <Tag className="text-white bg-indigo-600 border-0">
                                        {permissions}
                                    </Tag>
                                    }</li>
                              )
                            }
                            <li>Created at: {formatTimestamp(created_at)}</li>
                            <li>Updated at: {formatTimestamp(updated_at)}</li>
                        </ul>
                    </Card>
                </div>
           <Dialog
            isOpen={viewOpen}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
           >
            <h5>{selectedFile}</h5>
            <img className="w-full" src={selectedImg} alt={selectedFile} />
           </Dialog>
        </div>
    </>
  );
};



GridItem.propTypes = {
  data: PropTypes.shape({
    file_name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    link: PropTypes.string.isRequired,
    profile: PropTypes.number,
    thumb_link: PropTypes.string,
    type: PropTypes.string.isRequired,
    permissions: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
  files: PropTypes.any,
  fetchData: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setSelectedFile: PropTypes.func.isRequired,
  isSmallImage: PropTypes.bool,
};

export default GridItem;
