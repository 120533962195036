import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, Notification, Tabs } from "components/ui";
import isEmpty from "lodash/isEmpty";
import { Loading, DoubleSidedImage } from "components/shared";
import { getEntity, updateEntity, deleteEntity } from "../store/dataSlice";
import { getFieldOptions } from "../../ProgramsList/store/dataSlice";
import RecipientUploadNew from "../../RecipientUploadNew/index";
import RecipientEditForm from "../../../programs/RecipientEditForm/index";

const { TabNav, TabList, TabContent } = Tabs;

const DrawerEditRecipientForm = ({
  onSubmitComplete,
  onClose,
  entitiesId,
  program_id,
  onReload,
}) => {
  const dispatch = useDispatch();

  const entityData = useSelector(
    (state) => state.entitiesEdit.data.entityData.fields
  );

  const loading = useSelector(
    (state) => state.entitiesEdit.data.entityEditloading
  );
  const { client_id } = useSelector((state) => state.auth.user);

  const fetchData = (data) => {
    dispatch(getEntity(data));
  };

  const handleFormSubmit = async (values, setSubmitting) => {
    setSubmitting(true);
    try {
      const success = await updateEntity(values);
      setSubmitting(false);
      if (success) {
        popNotification("updated");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      const filteredMessage = errorMessage;
      toast.push(
        <Notification type="danger" duration={2500}>
          {error && filteredMessage ? (
            <span>{filteredMessage}</span>
          ) : (
            JSON.stringify(error)
          )}
        </Notification>,
        {
          placement: "top-center",
        }
      );
      setSubmitting(false);
    }
  };

  const handleDelete = async (setDialogOpen) => {
    setDialogOpen(false);
    try {
      const success = await deleteEntity({ entity_id: entityData.id });
      if (success) {
        popNotification("deleted");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      const messages = Object.values(errorMessage);
      const filteredMessage = messages[0][0];
      toast.push(
        <Notification type="danger" duration={2500}>
          {error && filteredMessage ? (
            <span>{filteredMessage}</span>
          ) : (
            JSON.stringify(error)
          )}
        </Notification>,
        {
          placement: "top-center",
        }
      );
    }
  };

  const popNotification = (keyword) => {
    toast.push(
      <Notification
        title={`Successfuly ${keyword}`}
        type="success"
        duration={2500}
      >
        Recipient successfuly {keyword}
      </Notification>,
      {
        placement: "top-center",
      }
    );
    onReload();
    onSubmitComplete();
  };

  useEffect(() => {
    fetchData({ client_id, id: entitiesId });

    if(program_id){
      dispatch(
        getFieldOptions({
          program_id: program_id,
          client_id,
          type: "all",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading loading={loading}>
        {!isEmpty(entityData) && (
          <>
            <Tabs defaultValue="edit">
              <TabList>
                <TabNav value="edit">Edit Recipient</TabNav>
                <TabNav value="upload">Files & Photos</TabNav>
              </TabList>
              <TabContent value="edit">
                <RecipientEditForm
                  initialData={entityData}
                  onFormSubmit={handleFormSubmit}
                  onDiscard={onClose}
                  onDelete={handleDelete}
                  entity_id={entitiesId}
                  onReload={onReload}
                />
              </TabContent>
              <TabContent value="upload">
                <RecipientUploadNew
                  onClose={onClose}
                  onReload={onReload}
                  entity_id={entitiesId}
                />
              </TabContent>
            </Tabs>
          </>
        )}
      </Loading>
      {!loading && isEmpty(entityData) && (
        <div className="h-full flex flex-col items-center justify-center">
          <DoubleSidedImage
            src="/img/others/img-2.png"
            darkModeSrc="/img/others/img-2-dark.png"
            alt="No product found!"
          />
          <h3 className="mt-8">No recipient data found!</h3>
        </div>
      )}
    </>
  );
};

export default DrawerEditRecipientForm;
