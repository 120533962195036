import React, { useState } from "react";
import {
  Button,
  Dialog,
  Input,
  Select,
  FormItem,
  FormContainer,
} from "components/ui";
import { apiPostEditFile } from "../../../services/ProgramsService";
import { Field, Form, Formik } from "formik";
import isEmpty from "lodash/isEmpty";

const Basic = ({ dialogIsOpen, setIsOpen, selectedFile, popNotification }) => {
  const [mfile, setFile] = useState(null);
  const onDialogClose = (e) => {
    setIsOpen(false);
  };


  const new_array = [
    { value: "doc", label: "Document" },
    { value: "image", label: "Image" },
  ];

  const permissions_array = [
    { value: "public", label: "Everyone" },
    { value: "donor", label: "Donor and Admins" },
    { value: "admin", label: "Admins Only" },
  ];

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const editFile = async (file, resetForm, setSubmitting) => {
    console.log("file", mfile);
    console.log("file", file);
    //save to the database without uploading the file to spaces
    const success = await apiPostEditFile({
      id: selectedFile.id,
      file_name: file.file_name,
      type: file.type,
      permissions: file.permissions,
    });
    if (success) {
      popNotification(success);
    }
    setIsOpen(false);
    setSubmitting(false);
    resetForm();
  };

  return (
    <>
      {!isEmpty(selectedFile) && (
        <div>
          <Dialog
            isOpen={dialogIsOpen}
            onClose={onDialogClose}
            onRequestClose={onDialogClose}
          >
            <div className="max-h-96 overflow-y-auto">
              <h5 className="mb-4">Edit Field</h5>
              <Formik
                initialValues={{
                  file_name: selectedFile.file_name,
                  type: "image",
                  permissions: "public",
                }}
                enableReinitialize
                onSubmit={(values, { resetForm, setSubmitting }) => {
                  editFile(values, resetForm, setSubmitting);
                }}
              >
                {({ touched, errors, resetForm }) => (
                  <Form>
                    <FormContainer>
                      <FormItem
                        label="File Name"
                        invalid={errors.file_name && touched.file_name}
                        errorMessage={errors.file_name}
                      >
                        <Field
                          type="text"
                          autoComplete="off"
                          name="file_name"
                          placeholder="File Name"
                          component={Input}
                        />
                      </FormItem>
                      <FormItem
                        label="File Type"
                        invalid={errors.type && touched.type}
                        errorMessage={errors.type}
                      >
                        <Field name="type">
                          {({ field, form }) => (
                            <Select
                              field={field}
                              form={form}
                              options={new_array}
                              value={new_array.filter(
                                (type) => type.label === selectedFile.type
                              )}
                              onChange={(option) => {
                                form.setFieldValue(field.name, option.value);
                              }}
                            />
                          )}
                        </Field>
                      </FormItem>
                      <FormItem
                        label="Permissions"
                        invalid={errors.permissions && touched.permissions}
                        errorMessage={errors.permissions}
                      >
                        <Field name="permissions">
                          {({ field, form }) => (
                            <Select
                              field={field}
                              form={form}
                              options={permissions_array}
                              value={permissions_array.filter(
                                (permissions) =>
                                  permissions.label === selectedFile.permissions
                              )}
                              onChange={(option) => {
                                form.setFieldValue(field.name, option.value);
                              }}
                            />
                          )}
                        </Field>
                      </FormItem>
                      <FormItem label="Replace File">
                        <Field
                          type="file"
                          autoComplete="off"
                          name="file"
                          placeholder="File Name"
                          onChange={handleFileChange}
                        />
                      </FormItem>
                      <FormItem>
                        <Button
                          type="reset"
                          className="ltr:mr-2 rtl:ml-2"
                          onClick={resetForm}
                        >
                          Reset
                        </Button>
                        <Button variant="solid" type="submit">
                          Submit
                        </Button>
                      </FormItem>
                    </FormContainer>
                  </Form>
                )}
              </Formik>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Basic;
