import React, { forwardRef, useCallback } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const ImageList = (props) => {

	const { imgUrl } = props

	return (
		<>
			<div>
        <iframe 
          src={imgUrl} 
          title={`Preview`}>
        </iframe>
      </div>
		</>
	)
}


const RichTextEditor = forwardRef((props, ref) => {
  const { state, setState } = props;

  const imageHandler = useCallback(() => {
    let input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
  
    input.onchange = () => {
      let file = input.files[0];

      setState(prevState => ({
        ...prevState,
        hasFile: true,
        imgUrl: URL.createObjectURL(file),
        file: file,
      }));
    };
  }, [setState])
  
  const modules = {
    toolbar: {
      handlers: {
        image: imageHandler,
      },
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['image', 'video'], // Add the image and video options
      ],
    },
  };
  
  const formats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
    'header', 'list', 'bullet', 'script', 'indent', 'direction',
    'size', 'color', 'background', 'font', 'align', 'clean',
    'image', 'video', // Include the image and video formats
  ];

  return (
    <div className="rich-text-editor">
      <ReactQuill ref={ref} {...props} modules={modules} formats={formats} />
      {state && state?.hasFile && <p>A file has been selected</p>}
      {state && state?.hasFile && 
        <ImageList 
          imgUrl={state.imgUrl} 
        />
      }
    </div>
  );
});

export default RichTextEditor;