import React from "react";
import { AdaptableCard, RichTextEditor } from "components/shared";
import { FormItem, Select } from "components/ui";
import { Field } from "formik";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";

const NotesEditFields = (props) => {
  const { touched, errors, values } = props;
  const categories = useSelector(
    (state) => state.getDonorDetails?.data?.notesList.categories
  );

  const categoryArray = [{
    label: "ALL",
    value: "ALL"
  }];

  categories?.forEach(function (category, i) {
    categoryArray.push({
      label: category.category,
      value: category.category,
      id: category.id,
    });
  });

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="lg:col-span-2">
          <h4 className="mb-5">Edit Note</h4>
          <AdaptableCard className="mb-4">
            <FormItem
              labelClass="!justify-start"
              invalid={errors.note && touched.note}
              errorMessage={errors.note}
            >
              <Field name="note">
                {({ field, form }) => (
                  <RichTextEditor
                    value={field.value}
                    onChange={(val) => form.setFieldValue(field.name, val)}
                  />
                )}
              </Field>
            </FormItem>
            <FormItem
              label="Category"
              invalid={errors.categories && touched.categories}
              errorMessage={errors.categories}
            >
              <Field name="categories">
                {({ field, form }) => (
                  <Select
                    componentAs={CreatableSelect}
                    isMulti
                    field={field}
                    form={form}
                    options={categoryArray}
                    value={values.categories}
                    onChange={(option) =>
                      form.setFieldValue(field.name, option)
                    }
                  />
                )}
              </Field>
            </FormItem>
            <p>Leave blank to leave the category unchanged.</p>
          </AdaptableCard>
        </div>
      </div>
    </>
  );
};

export default NotesEditFields;
