import React, { forwardRef, useState } from "react";
import { FormContainer, Button } from "components/ui";
import { StickyFooter, ConfirmDialog } from "components/shared";
import { Form, Formik } from "formik";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineSave } from "react-icons/ai";
import * as Yup from "yup";

import { useSelector } from "react-redux";
import MessageFields from "./MessageFields";

const validationSchema = Yup.object().shape({
  message: Yup.string().required("Message is Required"),
});

const DeleteProductButton = ({ onDelete }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const onConfirmDialogOpen = () => {
    setDialogOpen(true);
  };

  const onConfirmDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    onDelete?.(setDialogOpen);
  };

  return (
    <>
      <Button
        className="text-red-600"
        variant="plain"
        size="sm"
        icon={<HiOutlineTrash />}
        type="button"
        onClick={onConfirmDialogOpen}
      >
        Delete
      </Button>
      <ConfirmDialog
        isOpen={dialogOpen}
        onClose={onConfirmDialogClose}
        onRequestClose={onConfirmDialogClose}
        type="danger"
        title="Delete product"
        onCancel={onConfirmDialogClose}
        onConfirm={handleConfirm}
        confirmButtonColor="red-600"
      >
        <p>
          Are you sure you want to delete this product? All record related to
          this product will be deleted as well. This action cannot be undone.
        </p>
      </ConfirmDialog>
    </>
  );
};

const RecipientMessageForm = forwardRef((props, ref) => {
 
  const {
    type,
    initialData,
    onFormSubmit,
    onDiscard,
    onDelete,
    mresponse,
    btnLoading
  } = props;

  const { client_id } = useSelector((state) => state.auth.user);
  const { permissions } = useSelector((state) => state.auth.session);
  const [fileState, setFileState] = useState({ hasFile: false, file: null, imgUrl: "" });


  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  return (
    <>
      <Formik
        innerRef={ref}
        initialValues={{
          ...initialData,
          client_id: client_id
        }}
        validationSchema={fileState.hasFile ? undefined : validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (fileState.hasFile){
            values.image = fileState.file;
            values.imgUrl = fileState.imgUrl;
          }
          const formData = values;
          if(values.message) {
            formData.message = removeTags(values.message);
          }
          onFormSubmit?.(formData, setSubmitting);
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue, submitForm }) => (
          <Form>
            <FormContainer>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="lg:col-span-3">
                  <MessageFields
                    touched={touched}
                    errors={errors}
                    values={values}
                    mresponse={mresponse}
                    state={fileState}
                    setState={setFileState}
                  />
                </div>
              </div>
              <StickyFooter
                className="-mx-8 px-8 flex items-center justify-between py-4"
                stickyClass="border-t bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700"
              >
                <div>
                  {type === "edit" && (
                    <DeleteProductButton onDelete={onDelete} />
                  )}
                </div>
                <div className="md:flex items-center">
                  <Button
                    size="sm"
                    className="ltr:mr-3 rtl:ml-3"
                    onClick={() => onDiscard?.()}
                    type="button"
                  >
                    Discard
                  </Button>
                  <Button
                    size="sm"
                    variant="twoTone"
                    loading={btnLoading}
                    icon={<AiOutlineSave />}
                    type="button"
                    onClick={() => {
                      setFieldValue('draft', true).then(() => {
                        submitForm();
                      });
                    }}
                  >
                    Save As Draft
                  </Button>
                  {permissions.hasOwnProperty('disable_message_send_without_approval') && (permissions.disable_message_send_without_approval === false || permissions.disable_message_send_without_approval === null) && (
                    <Button
                      size="sm"
                      variant="solid"
                      loading={btnLoading}
                      icon={<AiOutlineSave />}
                      type="button"
                    onClick={() => {
                      setFieldValue('draft', false).then(() => {
                        submitForm();
                      });
                    }}
                    >
                    Send
                    </Button>
                  )}
                  {permissions.hasOwnProperty('disable_message_send_without_approval') === false && (
                    <Button
                      size="sm"
                      variant="solid"
                      loading={btnLoading}
                      icon={<AiOutlineSave />}
                      type="button"
                    onClick={() => {
                      setFieldValue('draft', false).then(() => {
                        submitForm();
                      });
                    }}
                    >
                    Send
                    </Button>
                  )}
                </div>
              </StickyFooter>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </>
  );
});

RecipientMessageForm.defaultProps = {
  type: "edit",
  initialData: {},
};

export default RecipientMessageForm;
