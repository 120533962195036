import React, { forwardRef, useState } from "react";
import { FormContainer, Button } from "components/ui";
import { StickyFooter, ConfirmDialog } from "components/shared";
import { Form, Formik } from "formik";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineSave } from "react-icons/ai";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import NotesFields from "./NotesFields";
import reducer from '../../donors/DonorDetail/store'

import { injectReducer } from 'store/index'

injectReducer('getDonorDetails', reducer)

const validationSchema = Yup.object().shape({
  note: Yup.string().required("Note is required"),
});

const DeleteProductButton = ({ onDelete }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const onConfirmDialogOpen = () => {
    setDialogOpen(true);
  };

  const onConfirmDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    onDelete?.(setDialogOpen);
  };

  return (
    <>
      <Button
        className="text-red-600"
        variant="plain"
        size="sm"
        icon={<HiOutlineTrash />}
        type="button"
        onClick={onConfirmDialogOpen}
      >
        Delete
      </Button>
      <ConfirmDialog
        isOpen={dialogOpen}
        onClose={onConfirmDialogClose}
        onRequestClose={onConfirmDialogClose}
        type="danger"
        title="Delete product"
        onCancel={onConfirmDialogClose}
        onConfirm={handleConfirm}
        confirmButtonColor="red-600"
      >
        <p>
          Are you sure you want to delete this product? All record related to
          this product will be deleted as well. This action cannot be undone.
        </p>
      </ConfirmDialog>
    </>
  );
};

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

const RecipientMessageForm = forwardRef((props, ref) => {
  const { type, initialData, onFormSubmit, onDiscard, onDelete, entity_id,program_id } =
    props;
    
  const { client_id } = useSelector((state) => state.auth.user);
  const donorId = useSelector((state) => state?.getDonorDetails?.data?.donorData?.data?.profile?.id)

  return (
    <>
      <Formik
        innerRef={ref}
        initialValues={{
          ...initialData,
          client_id: client_id,
          entity_id: entity_id,
          program_id: program_id,
          type: "entity",
          donor_id: donorId,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldValue }) => {
          const formData = values;

          formData.note = removeTags(values.note);
          formData.categories = formData.categories ? formData.categories[0].value :"";

          onFormSubmit?.(formData, setSubmitting);
          setFieldValue('note', "")
          setFieldValue('categories', "")
        }}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue }) => (
          <Form>
            <FormContainer>
              <div>
                <div>
                  <NotesFields
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </div>
              </div>
              <StickyFooter
                className="-mx-8 px-8 flex items-center justify-between py-4"
                stickyClass="border-t bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700"
              >
                <div>
                  {type === "edit" && (
                    <DeleteProductButton onDelete={onDelete} />
                  )}
                </div>
                <div className="md:flex items-center">
                  <Button
                    size="sm"
                    className="ltr:mr-3 rtl:ml-3"
                    onClick={() => onDiscard?.()}
                    type="button"
                  >
                    Discard
                  </Button>
                  <Button
                    size="sm"
                    variant="solid"
                    loading={isSubmitting}
                    icon={<AiOutlineSave />}
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </StickyFooter>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </>
  );
});

RecipientMessageForm.defaultProps = {
  type: "edit",
  initialData: {},
};

export default RecipientMessageForm;
