import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetNote,apiUpdateNote} from "services/ProgramsService";

export const getNote = createAsyncThunk('notesEdit/data/getNote', async (data) => {
    const response = await apiGetNote(data)
    return response.data
})

export const updateNote = async (data) => {
    const response = await apiUpdateNote(data)
    return response.data
}


const dataSlice = createSlice({
    name: 'noteEdit/data',
    initialState: {
        loading: false,
        noteData: [],
        
    },
    reducers: {
    },
    extraReducers: {
        [getNote.fulfilled]: (state, action) => {
            state.noteData = action.payload
            state.loading = false
        },
        [getNote.pending]: (state) => {
            state.loading = true
        },
    }
})

export default dataSlice.reducer
