import React, {useEffect,useState} from "react";
import {
  toast,
  Notification,
  Dialog,
} from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { getNotes, updateNote } from "../store/dataSlice";
import { closeAddNoteDialog, closeEditNote } from "../store/stateSlice";
import { useParams, useLocation } from "react-router-dom";
import RecipientNotesForm from '../../../programs/RecipientNotesForm/index'
import RecipientEditNoteForm from "../../../programs/RecipientEditNoteForm/index"
import { apiPostNotes } from "services/ProgramsService";

const AddNote = () => {
  const [ isReady, setIsReady ] = useState(false);
  const dispatch = useDispatch();
  const { donor_id } = useParams();

  const location = useLocation();
  const path = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const programId = useSelector((state) => state.getDonorDetails?.data?.donorData?.data?.hysform?.type)
  
  const progId = programId !== undefined ? programId : path

  const donor = location.state?.donor;
  const { client_id } = useSelector((state) => state.auth.user);

  const dialogOpen = useSelector(
    (state) => state.getDonorDetails.state.addNoteDialog
  );

  const editNote = useSelector(
    (state) => state.getDonorDetails?.state?.editNote
  );

  const entityId = useSelector(
    (state) => state.getDonorDetails?.state?.entityState
  );
  
  const noteData = useSelector(
    (state) => state.getDonorDetails?.data?.noteData?.note
  );

  const noteEntityData = useSelector(
    (state) => state.noteEdit?.data?.noteData?.note
  );

  const checkNoteData = noteData !== undefined ? noteData : noteEntityData

  useEffect(() => {
    if (noteData !== undefined) {
      setIsReady(true)
    }else if(noteEntityData !== undefined){
      setIsReady(true)
    }
  }, [noteData, noteEntityData])

  const onDialogClose = () => {
    dispatch(closeAddNoteDialog());
  };

  const onEditNoteClose = () => {
    setIsReady(false)
    dispatch(closeEditNote())
  }


  const fetchData = () => {
    if (donor) {
      dispatch(
        getNotes({
          id: donor_id,
          program_id: "donor",
          type: "donor",
          cat: "",
          client_id,
        })
      );
    } else if (entityId && entityId !== "") {
      dispatch(
        getNotes({
          id: entityId,
          program_id: progId,
          type: "entity",
          cat: "",
          client_id,
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  const addNotes = async (data) => {
    const response = await apiPostNotes(data);
    return response.data;
  };

  const handleUpdateFormSubmit = async (values, setSubmitting) => {
    setSubmitting(true);
    const success = await updateNote(values);
    setSubmitting(false);
    if (success) {
      toast.push(
        <Notification
          title={"Successfuly updated"}
          type="success"
          duration={2500}
        >
          Note successfuly updated
        </Notification>,
        {
          placement: "top-center",
        }
      );
      dispatch(closeEditNote());
      dispatch(
        getNotes({
          id: donor_id !== undefined ? donor_id : entityId ,
          program_id:  donor_id !== undefined ? "donor" : progId,
          type: donor_id !== undefined ? "donor" : "entity",
          cat: "",
          client_id,
        })
      );
    }
  };

  const handleFormSubmit = async (values, setSubmitting) => {
    setSubmitting(true);
    if (donor) {
      values.type = "donor";
      values.program_id = "donor";
      const success = await addNotes(values);
      setSubmitting(false);
      if (success) {
        toast.push(
          <Notification
            title={"Successfuly saved"}
            type="success"
            duration={2500}
          >
            Notes successfuly saved
          </Notification>,
          {
            placement: "top-center",
          }
        );
        //reload the page
        dispatch(
          getNotes({
            id: donor_id ,
            program_id: "donor",
            type: "donor",
            cat: "",
            client_id,
          })
        );
      }
    } else {
      const success = await addNotes(values);
      setSubmitting(false);
      if (success) {
        toast.push(
          <Notification
            title={"Successfuly saved"}
            type="success"
            duration={2500}
          >
            Notes successfuly saved
          </Notification>,
          {
            placement: "top-center",
          }
        );
        dispatch(
          getNotes({
            id: entityId,
            program_id: progId,
            type: "entity",
            cat: "",
            client_id,
          })
        );
      }
    }
  };

  return (
    <Dialog
      isOpen={dialogOpen}
      onClose={onDialogClose}
      onRequestClose={onDialogClose}
      width={1050}
    >
      { editNote && isReady ?
        <RecipientEditNoteForm
        type="edit"
        initialData={checkNoteData}
        onFormSubmit={handleUpdateFormSubmit}
        onDiscard={onEditNoteClose}
        program_id={progId}
        note_id={checkNoteData.id}
        client_id={client_id}
      />
      : 
        <RecipientNotesForm
        type="new"
        onFormSubmit={handleFormSubmit}
        onDiscard={onDialogClose}
        entity_id={donor_id !== undefined ? donor_id : entityId}
        program_id={progId}
      />
      }
      
      
    </Dialog>
  );
};

export default AddNote;
