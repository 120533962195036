import ApiService from "./ApiService";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/login",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/frontend/signup",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/logout",
    method: "get",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: "/password/forgot",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: "/password/reset",
    method: "post",
    data,
  });
}

export async function apiSendEmailVerify(data) {
  return ApiService.fetchData({
    url: "/email/verification-notification",
    method: "post",
    data,
  });
}

export async function apiEmailVerify(data) {
  return ApiService.fetchData({
    url: "/user/email/verification",
    method: "post",
    data,
  });
}

export async function apiSignUpOne(data) {
  return ApiService.fetchData({
    url: "/admin/signup/step_one",
    method: "post",
    data,
  });
}

export async function apiSignUpTwo(data) {
  return ApiService.fetchData({
    url: "/admin/signup/step_two",
    method: "post",
    data,
  });
}

export async function apiSignUpThree(data) {
  return ApiService.fetchData({
    url: "/admin/signup/step_three",
    method: "post",
    data,
  });
}

export async function apiPayNow(data) {
  return ApiService.fetchData({
    url: "/client/pay",
    method: "post",
    data,
  });
}
