import React, { useEffect, useState } from "react";
import RecipientUploadForm from "../RecipientUploadForm";
import { Card, toast, Notification, Button} from "components/ui";
import {
  apiUploadFile,
  apiGetFilesTable,
  apiPostUploadFile,
} from "../../../services/ProgramsService";
import AWS from "aws-sdk";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import Dialog from "./Dialog";
import CardView from "./CardView";
import { HiOutlineSave } from "react-icons/hi";
import JSZip from 'jszip';

const RecipientUploadNew = ({
  onClose,
  onReload,
  type,
  entity_id,
  donor_id,
}) => {
  const checkTypeDonor = type;
  const bucketName = process.env.REACT_APP_DO_BUCKET;
  const region = process.env.REACT_APP_DO_REGION;
  const { client_id } = useSelector((state) => state.auth.user);
  const [response, setResponse] = useState({});
  const [files, setFiles] = useState({});
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState([]);

  const downloadAllFilesAsZip = () => {
    const zip = new JSZip();

    // Check if fileData is loaded
    if (fileData.length === 0) {
        console.error('No files to zip');
        // Handle this case appropriately (maybe show an error message to the user)
        return;
    }

    // Add files to ZIP
    fileData.forEach(file => {
        if (file) {
            zip.file(file.name, file.blob);
        }
    });

    // Generate ZIP file and trigger download
    zip.generateAsync({ type: "blob" }).then(content => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(content);
        link.download = "files.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(error => {
        console.error('Error in zipping files:', error);
        // Handle the error appropriately
    });
};






  const headerExtraContent = (
    <span className="flex items-center md:items-center  flex-col sm:flex-row">
      <span className="mr-1 font-semibold">{files?.files?.length} Files</span>
      <Button
        className="block md:inline-block"
        variant="plain"
        size="sm"
        icon={<HiOutlineSave />}
        type="button"
        onClick={downloadAllFilesAsZip}
        disabled={isEmpty(files) || !fileData || fileData.length === 0}
      >
        Export All Files
      </Button>
    </span>
  );

  // Configure AWS credentials
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY_ID,
  });

  // Create an instance of the S3 service
  const s3 = new AWS.S3({
    params: { Bucket: bucketName },
    region: region,
  });

  const handleFormSubmit = async (values, setSubmitting) => {
    try {
      //loop
      values.imgList.forEach((img) => {
        let index = img.img.lastIndexOf("/");
        let result = img.img.substr(index + 1);
        const uploadParams = {
          Bucket: bucketName,
          Key: result + "." + img.name.split(".").pop(),
          Body: img.files,
          ContentType: img.files.type, // Setting the Content-Type metadata
          ACL: "public-read", // Adding the ACL property to make the file public
        };

        // Uploading the file to Spaces
        s3.putObject(uploadParams, async (err, data) => {
          if (err) {
            console.log(`Error uploading file: ${err}`);
          } else {
            //call api request
            const success = await apiPostUploadFile({
              name: img.name,
              status: 5,
              file_id: result,
              hys_type: response.type,
              hys_id: response.id,
              hys_ext: img.name.split(".").pop(),
              box_url: "",
              type: img.files.type,
              hys_slug: result,
              client_id,
            });

            if (success) {
              setSubmitting(false);
              popNotification(success);
              fetchData().catch((error) => console.log(error));
            }
          }
        });
      });
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  };

  const handleDiscard = () => {
    onClose();
  };

  const fetchData = async () => {
    let use_id = checkTypeDonor ? donor_id : entity_id;

    const success = await apiUploadFile({
      type: checkTypeDonor === true ? "donor" : "entity",
      id: use_id,
      client_id,
    });

    //files table
    const files = await apiGetFilesTable({
      type: checkTypeDonor === true ? "donor" : "entity",
      id: use_id,
      client_id,
    });
    if (success && files) {
      setResponse(success.data);
      setFiles(files.data);
      preFetchFiles(files.data.files);
    }
  };

  const preFetchFiles = async (files) => {
    const fetchedFiles = await Promise.all(files.map(async (file) => {
      try {
        const response = await fetch(file?.thumb_link);
        const blob = await response.blob();
        return { name: file.file_name, blob };
      } catch (error) {
        console.error('Error fetching file:', file.file_name, error);
        // Create an HTML blob with a clickable link
        const htmlContent = `<html><body><a href="${file.link}" target="_blank">Download ${file.file_name}</a></body></html>`;
        const linkBlob = new Blob([htmlContent], { type: 'text/html' });
        return { name: `${file.file_name}-link.html`, blob: linkBlob };
      }
    }));
  
    setFileData(fetchedFiles);
  };
  
  
  




 



  useEffect(() => {
    //request from api
    fetchData().catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const instructions = [
    "Click the edit button to rotate, delete or set as profile.",
    "The green background denotes an image set as the profile.",
    "Click the file-thumbnail to download it.",
  ];

  if (!isEmpty(files) && files?.useBox) {
    instructions.push(
      "Note: Rotate will not rotate full size files stored on Box.com"
    );
  }

  const popNotification = (keyword) => {
    toast.push(
      <Notification title={`Successful`} type="success" duration={2500}>
        {keyword.data.message}
      </Notification>,
      {
        placement: "top-center",
      }
    );
    //fresh the page
    fetchData();
  };

  const cardFooter = (
    <div className="flex items-center justify-center">
      <CardView
        data={files.files}
        files={files}
        fetchData={fetchData}
        setIsOpen={setIsOpen}
        setSelectedFile={setSelectedFile}
        onReload={onReload}
      />
    </div>
  );

  return (
    <div>
      <RecipientUploadForm
        type="new"
        response={response}
        onFormSubmit={handleFormSubmit}
        onDiscard={handleDiscard}
      />
      <Card
        header="Manage Files"
        headerExtra={headerExtraContent}
        footer={cardFooter}
      >
        <p>Instructions</p>
        {instructions.map((instruction, index) => (
          <li key={instruction}>{instruction}</li>
        ))}
      </Card>
      <Dialog
        dialogIsOpen={dialogIsOpen}
        setIsOpen={setIsOpen}
        selectedFile={selectedFile}
        popNotification={popNotification}
      />
    </div>
  );
};

export default RecipientUploadNew;
