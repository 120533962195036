import { createSlice, current } from "@reduxjs/toolkit";

const stateSlice = createSlice({
  name: "entitiesList/state",
  initialState: {
    deleteConfirmation: false,
    selectedEntities: "",
    selectedProduct: "",
    archiveState: false,
    selectedReports: "",
    sortedColumn: () => {},
    selectedRows: [],
    selectedRow: [],
    selectedArchiveRow: [],
    selectedArchiveRows: [],
    deleteMode: "",
    programState: "All Recipients",
    selectedFields: [],
    messageManagerDialog: false,
    equateState: false,
    query:"",
    keysArray:[]
  },
  reducers: {
    toggleDeleteConfirmation: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        deleteConfirmation: action.payload,
      };
    },
    setSelectedArchiveRows: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        selectedArchiveRows: action.payload,
      };
    },
    setSortedColumn: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        sortedColumn: action.payload,
      };
    },
    setSelectedEntities: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        selectedEntities: action.payload,
      };
    },
    setSelectedReports: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        selectedReports: action.payload,
      };
    },

    setSelectedRows: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        selectedRows: action.payload,
      };
    },
    setSelectedRow: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        selectedRow: action.payload,
      };
    },
    addRowItem: (state, { payload }) => {
      const currentState = current(state);
      if (!currentState.selectedRows.includes(payload)) {
        return {
          ...currentState,
          selectedRows: [...payload, ...currentState.selectedRows],
        };
      }
    },
    addArchiveRowItem: (state, { payload }) => {
      const currentState = current(state);
      if (!currentState.selectedArchiveRows.includes(payload)) {
        return {
          ...currentState,
          selectedArchiveRows: [
            ...payload,
            ...currentState.selectedArchiveRows,
          ],
        };
      }
    },
    removeRowItem: (state, { payload }) => {
      const currentState = current(state);
      if (currentState.selectedRows.includes(payload)) {
        return {
          ...currentState,
          selectedRows: currentState.selectedRows.filter(
            (id) => id !== payload
          ),
        };
      }
    },
    removeArchiveRowItem: (state, { payload }) => {
      const currentState = current(state);
      if (currentState.selectedArchiveRows.includes(payload)) {
        return {
          ...currentState,
          selectedArchiveRows: currentState.selectedArchiveRows.filter(
            (id) => id !== payload
          ),
        };
      }
    },
    setDeleteMode: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        deleteMode: action.payload,
      };
    },
    setArchiveMode: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        archiveState: action.payload,
      };
    },
    setProgramState: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        programState: action.payload,
      };
    },
    setSelectedFields: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        selectedFields: action.payload,
      };
    },
    clearSelectedFields: (state) => {
      const currentState = current(state);
      return {
        ...currentState,
        selectedFields: [],
      };
    },
    setEquateState: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        equateState: action.payload,
      };
    },
    setQuery: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        query: action.payload,
      };
    },
    setKeysArray: (state, action) => {
      const currentState = current(state);
      return {
        ...currentState,
        keysArray: action.payload,
      };
    },
    openMessageManagerDialog: (state) => {
      state.messageManagerDialog = true
    },
    closeMessageManagerDialog: (state) => {
      state.messageManagerDialog = false
    }
  },
});

export const {
  toggleDeleteConfirmation,
  setSortedColumn,
  setSelectedEntities,
  setSelectedReports,
  setSelectedRows,
  setSelectedRow,
  setSelectedArchiveRows,
  setSelectedArchiveRow,
  addRowItem,
  removeRowItem,
  setDeleteMode,
  setArchiveMode,
  addArchiveRowItem,
  removeArchiveRowItem,
  setProgramState,
  setSelectedFields,
  setEquateState,
  setQuery,
  setKeysArray,
  clearSelectedFields,
  openMessageManagerDialog,
  closeMessageManagerDialog,
} = stateSlice.actions;

export default stateSlice.reducer;
